import { useEffect, useRef } from 'react'

export const useOnce = (fn) => {
  const triggered = useRef(false)

  useEffect(() => {
    if (!triggered.current) {
      fn()
      triggered.current = true
    }
  }, [fn])

  return null
}
