import { Dispatch, SetStateAction, useState } from 'react'
import { singletonHook } from 'react-singleton-hook'
import { api } from 'lib/api'
import { assertBoom } from 'lib/assertBoom'
import { run } from 'lib/run'
import { useOnce } from 'lib/useOnce'
import { UserType } from 'models/User'

let globalSetMe: Dispatch<SetStateAction<UserType | undefined>>

function _useMe() {
  let [me, _setMe] = useState<UserType>()

  globalSetMe = _setMe

  useOnce(() => {
    void run(async () => {
      try {
        let _me = await api.get(`/api/auth/me`)
        _setMe(_me)
      } catch (err) {
        // document.location = `/`
      }
    })
  })

  return me
}

export const useMe = singletonHook(undefined, _useMe)
export const setMe = (me: UserType) => {
  assertBoom(globalSetMe, `useMe not used`, `badImplementation`)
  globalSetMe(me)
}
