import { useRef, useEffect } from 'react'

export default function Stars({ children }) {
  let c1 = useRef<HTMLCanvasElement>(null)
  let c2 = useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    if (!c1.current || !c2.current) return
    const canvas = c1.current
    const ctx = canvas.getContext(`2d`),
      canvas2 = c2.current,
      ctx2 = canvas2.getContext(`2d`),
      // full screen dimensions
      cw = window.innerWidth,
      ch = window.innerHeight,
      charArr = [`.`],
      // maxCharCount = 1,
      fallingCharArr: Point[] = [],
      fontSize = 15,
      maxColums = cw / fontSize
    canvas.width = canvas2.width = cw
    canvas.height = canvas2.height = ch

    function randomInt(min, max) {
      return Math.floor(Math.random() * (max - min) + min)
    }

    function randomFloat(min, max) {
      return Math.random() * (max - min) + min
    }

    class Point {
      started = false

      value!: string

      speed!: number

      constructor(public x: number, public y: number) {
        this.x = x
        this.y = y
      }

      draw(ctx) {
        this.value = charArr[randomInt(0, charArr.length - 1)].toUpperCase()
        this.speed = randomFloat(1, 5)

        ctx2!.fillStyle = `rgba(255,255,255,0.8)`
        ctx2!.font = fontSize + `px san-serif`
        ctx2!.fillText(this.value, this.x, this.y)

        ctx.fillStyle = `#00F`
        ctx.font = fontSize + `px san-serif`
        ctx.fillText(this.value, this.x, this.y)

        if (!this.started) {
          if (Math.random() < 0.001) this.started = true
          else return
        }
        this.y += this.speed
        if (this.y > ch) {
          this.y = randomFloat(-100, 0)
          this.speed = randomFloat(2, 5)
        }
      }
    }

    for (var i = 0; i < maxColums; i++) {
      fallingCharArr.push(new Point(i * fontSize, randomFloat(-500, 0)))
    }

    var update = function () {
      ctx!.fillStyle = `rgba(0,0,0,0.03)`
      ctx!.fillRect(0, 0, cw, ch)

      ctx2!.clearRect(0, 0, cw, ch)

      var i = fallingCharArr.length

      while (i--) {
        fallingCharArr[i].draw(ctx)
        // var v = fallingCharArr[i]
      }

      requestAnimationFrame(update)
    }

    update()
  }, [c1, c2])

  return (
    <>
      <canvas ref={c1} id='canvas' className='absolute top-0 left-0 block rotate-180'>
        Canvas is not supported in your browser.
      </canvas>
      <canvas ref={c2} id='canvas2' className='absolute top-0 left-0 block rotate-180'>
        Canvas is not supported in your browser.
      </canvas>
      <div className='center-all absolute top-0 left-0 h-full w-full flex-col text-white'>{children}</div>
    </>
  )
}
