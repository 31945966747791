/* eslint-disable no-console */

import Boom from 'boom'
import { compact, flatten, intersection, pick } from 'lodash'
import { inspect } from 'lib/inspect'

type colorful = (str: string) => string
let colors: {
  magenta: colorful
  red: colorful
  cyan: colorful
  yellow: colorful
}
try {
  colors = require(`colors/safe`)
} catch {
  colors = {
    magenta: (str) => str,
    red: (str) => str,
    cyan: (str) => str,
    yellow: (str) => str,
  }
}

let rtracer: { id: () => string } | null = null
try {
  rtracer = require(`cls-rtracer`)
} catch (e) {
  //
}

/**
 * log something
 * @param args
 */
export function log(...args: unknown[]): void {
  let id = rtracer?.id()
  if (id) {
    console.log(new Date().toISOString(), `trace:${id}`, ...args)
  } else {
    console.log(new Date().toISOString(), ...args)
  }
}

/**
 * debug something, meaning it gets logged if the associated debug tags are enabled
 * @param tags the tags associated with the debug state
 * @param message the message to debug. all debug statements should start with a string to represent the "what" before passing in objects to be debugged.
 * @param args
 */
export function debug(tags: string | string[], message: string, ...args: unknown[]): void {
  if (tagsActive(tags)) {
    log(colors.cyan(`[${tags}]`), message, ...args)
  }
}

export function trace(tags: string | string[], message: string, ...args: unknown[]): void {
  if (tagsActive(tags)) {
    console.trace(`[${tags}]`, message, ...args)
  }
}

/**
 * log an error
 * @param message message explaining what the error is.
 * @param args args to log. usually the error object.
 */
export function logError(message: string, ...args: any[]): void {
  let id = rtracer?.id()
  args = args.map((arg) => {
    if (arg?.isAxiosError) {
      return inspect(
        pick(
          arg,
          `message`,
          `name`,
          `code`,
          `status`,
          `config.url`,
          `config.method`,
          `config.data`,
          `config.headers`,
          `response.data`,
          `stack`,
        ),
      )
    }
    if (Boom.isBoom(arg)) {
      return `- BoomError: ${inspect(arg.output, false)}-${arg.message}-${arg.stack}`
    }
    return arg
  })
  if (id) {
    console.error(new Date(), colors.red(`[error]`), `trace:${id}`, colors.red(message) || message, ...args)
  } else {
    console.error(new Date(), colors.red(`[error]`), colors.red(message) || message, ...args)
  }
}

export function logWarn(message: string, ...args: any[]): void {
  let id = rtracer?.id()
  if (id) {
    console.warn(new Date(), colors.yellow(`[warning]`), `trace:${id}`, colors.yellow(message) || message, ...args)
  } else {
    console.warn(new Date(), colors.yellow(`[warning]`), colors.yellow(message) || message, ...args)
  }
}

/**
 * check if the given tags are currently active
 * @param tags
 */
export function tagsActive(tags: string | string[]): boolean {
  let activeTags: any = process.env.DEBUG_TAGS

  if (!activeTags) {
    return false
  }
  if (activeTags !== `true`) {
    activeTags = compact(activeTags.split(/,/).map((t) => t.trim()))
    if (intersection(activeTags, flatten([tags])).length === 0) {
      return false
    }
  }
  return true
}
