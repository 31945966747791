import Link from 'next/link'
import { useEffect } from 'react'
import Stars from 'components/Stars'
import { useMe } from 'lib/useMe'

export default function Home() {
  let me = useMe()
  useEffect(() => {
    if (me) {
      window.document.location = `/account`
    }
  }, [me])

  return (
    <div>
      <Stars>
        <div className='absolute top-0 right-0 p-5 text-xl text-sky-800'>
          <Link href='/login'>Log In</Link>
        </div>
        <h1 className='text-8xl'>CheapVeep</h1>
        <div className='text-center text-xl text-sky-500'>
          <h5>The cheapest VPN service on the internet!</h5>
          <h5>
            On sale for $4 (normally $8) per month, billed monthly, <br />
            for unlimited personal devices and unlimited bandwidth.
          </h5>
          <Link href='/signup' className='mt-5 h-4 animate-pulse text-xl underline hover:animate-none hover:text-2xl'>
            Let's get started
          </Link>
        </div>
      </Stars>
    </div>
  )
}
