import { debug } from 'lib/logging'

export class ResponseError extends Error {
  status: number

  body: string

  constructor({ status, body }) {
    super(body)
    this.status = status
    this.body = body
  }
}

async function request(method: `get` | `post` | `delete` | `put` | `patch`, path: string, body?: any) {
  let resp = await window.fetch(process.env.HOST + path, {
    method,
    headers: { 'content-type': `application/json` },
    body: body && JSON.stringify(body),
  })
  if (resp.status >= 400) {
    throw new ResponseError({ status: resp.status, body: await resp.text() })
  }
  return resp.json()
}

export const api = {
  post: async (path: string, body: any) => {
    debug(`api`, `api host`, process.env.HOST)
    return request(`post`, path, body)
  },

  get: async (path: string) => {
    return request(`get`, path)
  },
}
